import React from 'react'
import { Box, Text, Heading, Stack, Link } from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

const TermsOfService = () => {
  return (
    <Layout>
      <SEO title="Terms of Service" />
      <Stack maxWidth="2xl" fontSize="xl" spacing={8}>
        <Heading mb={8} mt={9} as="h1" textAlign="left">
          Terms Of Service
        </Heading>
        <Text fontStyle="italic">Last modified: September 4, 2020</Text>

        <Text>
          Thank you for choosing Snapiant. By accessing or using the Snapiant
          website at <Link color="teal.500">https://www.snapiant.com</Link>
          ("Site") or the Snapiant related services ("Service"), you agree to
          the following Terms of Service. If you do not agree to the following
          Terms of Service, please do not use the Site or the Service.
        </Text>

        <Text>
          The following terms and conditions, as well as any future
          modifications (the “Terms”) are a contract between you or your company
          (“you” or “your”) and Snapiant. When we say "Services", "Company",
          “we,” “us,” or “our” in in this document we are referring to Snapiant.
          By using Snapiant website, including, but not limited to
          https://www.snapiant.com, software applications and application
          programming interfaces (collectively, the “Services”), you acknowledge
          that you have read, understood, accept and agree to be bound by the
          most recent version of these Terms.
        </Text>

        <Text>
          When you use our Services, now or in the future, you are agreeing to
          the latest Terms of Service. That’s true for any of our existing and
          future products and all features that we add to our Services over
          time. There may be times where we do not exercise or enforce any right
          or provision of the Terms of Service; in doing so, we are not waiving
          that right or provision. These terms do contain a limitation of our
          liability.
        </Text>

        <Heading as="h2" size="lg">
          Account Terms
        </Heading>
        <Box as="ol">
          <Text as="li">
            You are responsible for maintaining the security of your account and
            password. The Company cannot and will not be liable for any loss or
            damage from your failure to comply with this security obligation. We
            recommend users set up two-factor authentication for added security.
            In some of our Services, we may require it.
          </Text>
          <Text as="li">
            You are responsible for all content posted and activity that occurs
            under your account. That includes content posted by others who
            either: (a) have access to your login credentials; or (b) have their
            own logins under your account.
          </Text>

          <Text as="li">
            You must be a human. Accounts registered by “bots” or other
            automated methods are not permitted.
          </Text>
        </Box>

        <Heading as="h2" size="lg">
          Payment, Refunds, and Plan Changes
        </Heading>
        <Box as="ol">
          <Text as="li">
            If you are using a free version of one of our Services, it is really
            free: we do not ask you for your credit card and — just like for
            customers who pay for our Services — we do not sell your data.
          </Text>

          <Text as="li">
            If you are upgrading from a free plan to a paid plan, we will charge
            your card immediately and your billing cycle starts on the day of
            upgrade. For other upgrades or downgrades in plan level, the new
            rate starts from the next billing cycle.
          </Text>
          <Text as="li">
            All fees are exclusive of all taxes, levies, or duties imposed by
            taxing authorities. Where required, we will collect those taxes on
            behalf of the taxing authority and remit those taxes to taxing
            authorities.
          </Text>
        </Box>

        <Heading as="h2" size="lg">
          Cancellation and Termination
        </Heading>
        <Box as="ol">
          <Text as="li">
            You are solely responsible for properly canceling your account.
            Within each of our Services, we provide a simple no-questions-asked
            cancellation link. An email or chat request to cancel your account
            is not automatically considered cancellation. If you need help
            cancelling your account, you can always{' '}
            <Link as={GatsbyLink} to="/contact">
              contact us
            </Link>
          </Text>

          <Text as="li">
            If you cancel the Service before the end of your current paid up
            month, your cancellation will take effect immediately, and you will
            not be charged again.
          </Text>
          <Text as="li">
            We have the right to suspend or terminate your account and refuse
            any and all current or future use of our Services for any reason at
            any time. Suspension means you and any other users on your account
            will not be able to access the account or any content in the
            account. Termination will furthermore result in the deletion of your
            account or your access to your account, and the forfeiture and
            relinquishment of all content in your account. We also reserve the
            right to refuse the use of the Services to anyone for any reason at
            any time. We have this clause because statistically speaking, out of
            the hundreds of thousands of accounts on our Services, there is at
            least one doing something nefarious. There are some things we
            staunchly stand against and this clause is how we exercise that
            stance.
          </Text>
          <Text as="li">
            Verbal, physical, written or other abuse (including threats of abuse
            or retribution) of Company employee or officer will result in
            immediate account termination.
          </Text>
        </Box>

        <Heading as="h2" size="lg">
          Modifications to the Service and Prices
        </Heading>
        <Box as="ol">
          <Text as="li">
            We make a promise to our customers to support our Services until the
            end of the Internet. That means when it comes to security, privacy,
            and customer support, we will continue to maintain any legacy
            Services. Sometimes it becomes technically impossible to continue a
            feature or we redesign a part of our Services because we think it
            could be better or we decide to close new signups of a product. We
            reserve the right at any time to modify or discontinue, temporarily
            or permanently, any part of our Services with or without notice.
          </Text>
          <Text as="li">
            Sometimes we change the pricing structure for our products. When we
            do that, we tend to exempt existing customers from those changes.
            However, we may choose to change the prices for existing customers.
            If we do so, we will give at least 30 days notice and will notify
            you via the email address on record. We may also post a notice about
            changes on our websites or the affected Services themselves.
          </Text>
        </Box>

        <Heading as="h2" size="lg">
          Uptime, Security, and Privacy
        </Heading>
        <Box as="ol">
          <Text as="li">
            Your use of the Services is at your sole risk. We provide these
            Services on an “as is” and “as available” basis. We do not offer
            service-level agreements for our Services.
          </Text>
          <Text as="li">
            We reserve the right to temporarily disable your account if your
            usage significantly exceeds the average usage of other customers of
            the Services. Of course, we'll reach out to the account owner before
            taking any action except in rare cases where the level of use may
            negatively impact the performance of the Service for other
            customers.
          </Text>
          <Text as="li">
            When you use our Services, you entrust us with your data. We take
            that trust to heart. You agree that Basecamp may process your data
            as described in our{' '}
            <Link as={GatsbyLink} color="teal.500" to="/privacy-policy">
              Privacy Policy
            </Link>{' '}
            and for no other purpose. We as humans can access your data for the
            following reasons:
          </Text>
          <Text as="li">
            We use third party vendors and hosting partners to provide the
            necessary hardware, software, networking, storage, and related
            technology required to run the Services.
          </Text>
        </Box>

        <Heading as="h2" size="lg">
          Copyright and Content Ownership
        </Heading>
        <Box as="ol">
          <Text as="li">
            We claim no intellectual property rights over the material you
            provide to the Services. All materials uploaded remain yours.
          </Text>
          <Text as="li">
            We do not pre-screen content, but reserve the right (but not the
            obligation) in our sole discretion to refuse or remove any content
            that is available via the Service.
          </Text>
          <Text as="li">
            The names, look, and feel of the Services are copyright© to the
            Company. All rights reserved. You may not duplicate, copy, or reuse
            any portion of the HTML, CSS, JavaScript, or visual design elements
            without express written permission from the Company. You must
            request permission to use the Company's logo or any Service logos
            for promotional purposes. Please{' '}
            <Link textDecoration="underline" href="mailto:support@snapiant.com">
              email us
            </Link>{' '}
            requests to use logos. We reserve the right to rescind this
            permission if you violate these Terms of Service.
          </Text>
          <Text as="li">
            You agree not to reproduce, duplicate, copy, sell, resell or exploit
            any portion of the Services, use of the Services, or access to the
            Services without the express written permission by the Company.
          </Text>
          <Text as="li">
            You must not modify another website so as to falsely imply that it
            is associated with the Services or the Company.
          </Text>
        </Box>
        <Heading as="h2" size="lg">
          Features and Bugs
        </Heading>
        <Box as="ol">
          <Text as="li">
            We design our Services with care, based on our own experience and
            the experiences of customers who share their time and feedback.
            However, there is no such thing as a service that pleases everybody.
            We make no guarantees that our Services will meet your specific
            requirements or expectations.
          </Text>
          <Text as="li">
            We also test all of our features extensively before shipping them.
            As with any software, our Services inevitably have some bugs. We
            track the bugs reported to us and work through priority ones,
            especially any related to security or privacy. Not all reported bugs
            will get fixed and we don't guarantee completely error-free
            Services.
          </Text>
        </Box>
        <Heading as="h2" size="lg">
          Services Adaptations and API Terms
        </Heading>
        <Text>
          We offer Application Program Interfaces ("API"s) for some of our
          Services. Any use of the API, including through a third-party product
          that accesses the Services, is bound by the terms of this agreement
          plus the following specific terms:
        </Text>
        <Box as="ol">
          <Text as="li">
            You expressly understand and agree that we are not liable for any
            damages or losses resulting from your use of the API or third-party
            products that access data via the API.
          </Text>
          <Text as="li">
            Third parties may not access and employ the API if the functionality
            is part of an application that remotely records, monitors, or
            reports a Service user's activity other than time tracking, both
            inside and outside the applications. The Company, in its sole
            discretion, will determine if an integration service violates this
            bylaw. A third party that has built and deployed an integration for
            the purpose of remote user surveillance will be required to remove
            that integration.
          </Text>
          <Text as="li">
            Abuse or excessively frequent requests to the Services via the API
            may result in the temporary or permanent suspension of your
            account's access to the API. The Company, in its sole discretion,
            will determine abuse or excessive usage of the API. If we need to
            suspend your account's access, we will attempt to warn the account
            owner first. If your API usage could or has caused downtime, we may
            cut off access without prior notice.
          </Text>
        </Box>
        <Heading as="h2" size="lg">
          Liability
        </Heading>
        <Text>
          We mention liability throughout these Terms but to put it all in one
          section:
        </Text>
        <Text fontWeight="bold" fontStyle="italic">
          You expressly understand and agree that the Company shall not be
          liable, in law or in equity, to you or to any third party for any
          direct, indirect, incidental, lost profits, special, consequential,
          punitive or exemplary damages, including, but not limited to, damages
          for loss of profits, goodwill, use, data or other intangible losses
          (even if the Company has been advised of the possibility of such
          damages), resulting from: (i) the use or the inability to use the
          Services; (ii) the cost of procurement of substitute goods and
          services resulting from any goods, data, information or services
          purchased or obtained or messages received or transactions entered
          into through or from the Services; (iii) unauthorized access to or
          alteration of your transmissions or data; (iv) statements or conduct
          of any third party on the service; (v) or any other matter relating to
          this Terms of Service or the Services, whether as a breach of
          contract, tort (including negligence whether active or passive), or
          any other theory of liability.
        </Text>

        <Text>
          If you have a question about any of the Terms of Service, please{' '}
          <Link textDecoration="underline" as={GatsbyLink} to="/contact">
            contact us
          </Link>
          .
        </Text>
      </Stack>
    </Layout>
  )
}

export default TermsOfService
